import { Environment } from '@abp/ng.core';

const baseUrl = 'https://harvest-activity-report-web-dev.upmuruguay.net';

const oAuthConfig = {
  issuer: 'https://harvest-activity-report-web-backend-dev.upmuruguay.net/',
  redirectUri: baseUrl,
  clientId: 'HarvestActivityReportWeb_App',
  responseType: 'code',
  scope: 'offline_access HarvestActivityReportWeb',
  requireHttps: true,
};

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'HarvestActivityReportWeb',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://harvest-activity-report-web-backend-dev.upmuruguay.net',
      rootNamespace: 'Upm.HarvestActivityReportWeb',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    }
  },
  applicationInsights: {
    isEnabled: 'true',
    instrumentationKey: '4e1e07a7-d852-44b1-84ce-e1cc004f8d0c'
  },
} as Environment;
